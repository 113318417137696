import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

// import { MuiPickersUtilsProvider } from "material-ui-pickers";
// import MomentUtils from "@date-io/moment";
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'

import { ConfigContextProvider } from './components/Config'

import * as serviceWorker from './serviceWorker'

import theme from './components/Theme'
import App from './components/App'

let configUrl = 'config.json'
if (process.env.NODE_ENV === 'production') {
  configUrl = 'https://api.jsonbin.io/v3/b/5df27c97cb4ac6042078b756'
}

//fetch('https://api.jsonbin.io/b/5df27c97cb4ac6042078b756/latest')
//fetch('config.json')
fetch(configUrl)
  .then(response => {
    return response.json()
  })
  .then(data => {
    ReactDOM.render(
      // <MuiPickersUtilsProvider utils={MomentUtils}>
      <ConfigContextProvider config={data.record}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <App />
        </ThemeProvider>
      </ConfigContextProvider>,
      // </MuiPickersUtilsProvider>,
      document.getElementById('root')
    )
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
