import React, { useState, useEffect, useRef, useContext } from 'react'

import { StaticMap } from 'react-map-gl'
import DeckGL from '@deck.gl/react'
import { MapboxLayer } from '@deck.gl/mapbox'
import { ScatterplotLayer, PathLayer } from '@deck.gl/layers'
import { GeoJsonLayer } from '@deck.gl/layers'
import { IconLayer } from '@deck.gl/layers'
import { TripsLayer } from '@deck.gl/geo-layers'
import { HeatmapLayer } from '@deck.gl/aggregation-layers'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

import { makeStyles, withStyles } from '@material-ui/core/styles'

import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'

import SyncIcon from '@material-ui/icons/Sync'
import Fade from '@material-ui/core/Fade'
import LinearProgress from '@material-ui/core/LinearProgress'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import axios from 'axios'
import throttledAxios from './ThrottledAxios'

import StationDrawer from './StationDrawer'
import BusStationDrawer from './BusStationDrawer'
import BusDrawer from './BusDrawer'
import Metric from '../Metric'
import ProgressBar from '../Progress'

import OnboardingDialog from '../Onboarding/OnboardingDialog'
import { TitleIllustrationTextCard, IllustrationSubtitleTextCard } from '../Onboarding/Cards'
import { getSteps } from '../Onboarding/BusOnboarding'

import templates from './Templates'

import { ConfigContext } from '../Config'

const MapboxApikey = process.env.REACT_APP_MAPBOX_TOKEN
const TMBAppId = process.env.REACT_APP_TMB_APPID
const TMBAppKey = process.env.REACT_APP_TMB_APPKEY

const hexToRgb = hex =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))

const useStyles = makeStyles(theme => ({
  map: {
    background: '#050505'
  },
  mapContainer: {
    position: 'relative',
    height: '90vh', // 70vw if social present
    [`@media (max-width:1079px)`]: {
      height: '85vh'
    },
    [`@media (orientation: landscape)`]: {
      height: '75vh' // 50vh if social present
    }
  },
  paperTop: {
    position: 'absolute',
    background: 'transparent',
    top: 0,
    left: 0,
    zIndex: 101,
    padding: theme.spacing(2),
    width: '100vw'
  },
  paperTopLeft: {
    position: 'absolute',
    background: 'transparent',
    top: 0,
    left: 0,
    zIndex: 98,
    margin: theme.spacing(2),
    color: theme.palette.common.white
  },
  paperTopRight: {
    position: 'absolute',
    background: 'transparent',
    top: 0,
    right: 0,
    zIndex: 98,
    margin: theme.spacing(2),
    color: theme.palette.common.white
  },
  paperBottomRight: {
    position: 'absolute',
    background: 'transparent',
    top: '85vh',
    right: 0,
    zIndex: 98,
    margin: theme.spacing(2),
    color: theme.palette.common.white,
    [`@media (max-width:1079px)`]: {
      top: '78vh'
    },
    [`@media (orientation: landscape)`]: {
      top: '63vh'
    }
  },
  refreshButton: {
    color: 'white'
  }
}))

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#050505'
  },
  barColorPrimary: {
    backgroundColor: '#7d7d7d'
  }
})(LinearProgress)

// station base info
// https://api.bsmsa.eu/ext/api/bsm/gbfs/v2/en/station_information
// station bike status
// https://api.bsmsa.eu/ext/api/bsm/gbfs/v2/en/station_status
// common id: station_id

// Initial viewport settings
const initialViewState = {
  latitude: 41.40111,
  longitude: 2.193287,
  zoom: 14,
  pitch: 0,
  bearing: 0,
  minZoom: 12,
  maxZoom: 16
}

// Animation parameters
const DURATION = {
  LOOPING: 5,
  FLASHING: 60
}

// Time of reference
const TIME_ORIGIN = Date.now()

// Hook to get bike realtime info
const useBikeInfo = () => {
  const [info, setInfo] = useState({
    last_updated: null,
    ttl: 100,
    data: { stations: [] }
  })
  const [update, setUpdate] = useState({
    nowAvailable: null,
    prevAvailable: null,
    variation: null
  })
  const url = 'https://api.bsmsa.eu/ext/api/bsm/gbfs/v2/en/station_status'
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [hasExpired, setHasExpired] = useState(true)

  // When there is new info, update
  useEffect(() => {}, [info])

  // When expires, fetch again
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)

      try {
        const result = await axios(url)
        // set data
        setInfo(result.data)

        // aggregate available bikes
        let sum = 0
        for (let i = 0, len = result.data.data.stations.length; i < len; i += 1) {
          sum += result.data.data.stations[i].num_bikes_available
        }
        console.log(`Counted ${sum} bikes`)

        setUpdate(lastUpdate => {
          // console.log(lastUpdate)
          return {
            nowAvailable: sum,
            prevAvailable: lastUpdate.nowAvailable,
            variation: lastUpdate.nowAvailable == null ? 0 : sum - lastUpdate.nowAvailable
          }
        })

        // set expiration and start update timer
        setHasExpired(false)
        console.log(`Setting expiration in ${result.data.ttl} seconds.`)
        const timer = setTimeout(() => {
          setHasExpired(true)
          console.log('Has expired!')
        }, result.data.ttl * 1000)
        return () => clearTimeout(timer)
      } catch (error) {
        setIsError(true)
      }

      setIsLoading(false)
      return null
    }

    if (hasExpired) {
      fetchData()
    }
  }, [hasExpired])

  return [{ info, update, isLoading, isError }]
}

// https://www.geodatasource.com/developers/javascript
function getDistance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  }
  const radlat1 = (Math.PI * lat1) / 180
  const radlat2 = (Math.PI * lat2) / 180
  const theta = lon1 - lon2
  const radtheta = (Math.PI * theta) / 180
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  if (dist > 1) {
    dist = 1
  }
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  if (unit === 'K') {
    dist *= 1.609344
  }
  if (unit === 'N') {
    dist *= 0.8684
  }
  return dist
}

function Map() {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)

  const busStations = templates[config.stations]

  // Control init state
  const [init, setInit] = useState(false)
  const [initProgress, setInitProgress] = useState(0)
  const PROGRESS_STEP = 25

  // Store station info
  const [info, setInfo] = useState({
    last_updated: null,
    ttl: 100,
    data: { stations: [] }
  })

  // Store a coordinates dictionary to make lookups from bike realtime info (doesnt't return coordinates)
  const [coordinates, setCoordinates] = useState({})
  const url = 'https://api.bsmsa.eu/ext/api/bsm/gbfs/v2/en/station_information'
  const [, setIsLoading] = useState(false)
  const [, setIsError] = useState(false)

  // Store bus routes
  const [counter0, setCounter0] = useState({})
  const [counter, setCounter] = useState(0)
  const [counter2, setCounter2] = useState(0)
  const [counter3, setCounter3] = useState(0)
  const [refreshCount, setRefreshCount] = useState(0)
  const [loadingVehicles, setLoadingVehicles] = useState(true)
  const [stations, setStations] = useState({
    type: 'FeatureCollection',
    features: []
  })
  const [lines, setLines] = useState({})
  const [exchanges, setExchanges] = useState({
    type: 'FeatureCollection',
    features: []
  })
  const [routes, setRoutes] = useState({
    type: 'FeatureCollection',
    features: []
  })
  const [trips, setTrips] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [schedules, setSchedules] = useState([])
  const [scheduledVehicles, setScheduledVehicles] = useState([])
  const [paths, setPaths] = useState([])
  const [lastUpdate, setLastUpdate] = useState(Date.now())
  const [variation, setVariation] = useState(0)

  // Get Bicing stations on first load
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)

      try {
        const result = await axios(url)
        setInfo(result.data)
        const c = {}
        for (let i = 0, len = result.data.data.stations.length; i < len; i += 1) {
          c[result.data.data.stations[i].station_id] = {
            lon: result.data.data.stations[i].lon,
            lat: result.data.data.stations[i].lat
          }
        }
        setCoordinates(c)
      } catch (error) {
        setIsError(true)
      }

      setIsLoading(false)
      setInitProgress(prev => prev + PROGRESS_STEP)
    }

    fetchData()
  }, [])

  // Start getting periodic bike status
  const [bikes] = useBikeInfo()

  // Find basic bus data for stations on first load
  useEffect(() => {
    // Fetch station data
    const fetchStationData = async station => {
      try {
        const result = await throttledAxios(
          `https://api.tmb.cat/v1/transit/parades/${station}?app_id=${TMBAppId}&app_key=${TMBAppKey}`
        )

        console.log(`station ${station} fetched`)

        // Save all station data
        setStations(prevStations => ({
          type: 'FeatureCollection',
          features: [...prevStations.features, ...result.data.features]
        }))
      } catch (error) {}
    }

    // Fetch possible exchanges for station
    const fetchExchangeData = async station => {
      try {
        const result = await throttledAxios(
          `https://api.tmb.cat/v1/transit/parades/${station}/corresp?app_id=${TMBAppId}&app_key=${TMBAppKey}`
        )

        console.log(`station ${station} has ${result.data.features.length} exchanges`)

        // Save all exchange points (pairs station-route)
        setExchanges(prevExchanges => ({
          type: 'FeatureCollection',
          features: [...prevExchanges.features, ...result.data.features]
        }))

        // Set summary of stations
        setCounter0(prevCounter => ({
          ...prevCounter,
          [station]: result.data.features.length
        }))

        result.data.features.forEach(exchange => {
          // Consolidate lines
          setLines(prevLines => ({
            ...prevLines,
            [exchange.properties.CODI_LINIA]: prevLines[exchange.properties.CODI_LINIA]
              ? prevLines[exchange.properties.CODI_LINIA] + 1
              : 1
          }))

          setCounter(prevCounter => prevCounter + 1)

          //fetchRouteData(exchange.properties.CODI_LINIA)
          //fetchNextVehicle(exchange.properties.CODI_LINIA, station)
        })
      } catch (error) {}
    }

    busStations.forEach(station => {
      fetchStationData(station)
      fetchExchangeData(station)
    })
  }, [busStations])

  // Translate geojson geometry into trip waypoint structure
  const geometryToWaypoints = (feature, id, properties) => {
    const geometry = feature.geometry.coordinates[0]

    const trip = {
      waypoints: [],
      properties,
      id: id || feature.id,
      //color,
      length: 0
      //ID_RECORREGUT,
      //CODI_LINIA
    }

    const { length } = geometry

    // find total path distance
    let distance = 0
    geometry.forEach((point, i) => {
      if (i < length - 1) {
        distance += getDistance(point[0], point[1], geometry[i + 1][0], geometry[i + 1][1], 'K')
      }
    })

    // fake timestamps based on accumulative distance, from 0 to 100
    let accum = 0
    geometry.forEach((point, i) => {
      if (i > 0) {
        accum += getDistance(point[0], point[1], geometry[i - 1][0], geometry[i - 1][1], 'K')
      }
      //console.log((100 * accum) / distance)
      trip.waypoints.push({
        coordinates: point,
        timestamp: (100 * accum) / distance,
        percentage: (100 * accum) / distance,
        type: 'Point'
      })
    })

    trip.length = distance

    /*setTrips(prevTrips =>
      [trip, ...prevTrips].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
    )*/
    //console.log(trip)
    //console.log(distance)
    return trip
  }

  useEffect(() => {
    console.log(
      `exchanges: ${counter} of ${exchanges.features.length}, stations: ${
        Object.keys(counter0).length
      } of ${busStations.length}, found ${Object.keys(lines).length} lines`
    )

    // Fetch routes of line
    const fetchRouteData = async line => {
      try {
        const result = await throttledAxios(
          `https://api.tmb.cat/v1/transit/linies/bus/${line}/recs?app_id=${TMBAppId}&app_key=${TMBAppKey}`
        )

        console.log(`line ${line} has ${result.data.features.length} routes`)

        /*setRoutes(prevRoutes => ({
          type: 'FeatureCollection',
          features: [...prevRoutes.features, ...result.data.features]
        }))*/

        // Save routes without duplicates
        setRoutes(prevRoutes => ({
          type: 'FeatureCollection',
          features: [...prevRoutes.features, ...result.data.features].filter(
            (v, i, a) => a.findIndex(t => t.id === v.id) === i
          )
        }))

        // Count as processed
        setCounter2(prevCounter => prevCounter + 1)

        result.data.features.forEach(feature => {
          const trip = geometryToWaypoints(feature, feature.id, feature.properties)
          setTrips(prevTrips =>
            [trip, ...prevTrips].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
          )
        })
      } catch (error) {}
    }

    if (
      counter === exchanges.features.length &&
      Object.keys(counter0).length === busStations.length
    ) {
      console.log(
        `### STEP OK: base station data and exchanges processed, fetching routes for ${
          Object.keys(lines).length
        } lines`
      )
      setInitProgress(prev => prev + PROGRESS_STEP)

      // fetch routes for each unique line found
      Object.keys(lines).forEach(id => fetchRouteData(id))
    }
  }, [busStations.length, counter, counter0, exchanges.features, lines, stations])

  useEffect(() => {
    // Get vehicle positions
    const getPositionsForVehicle = (vehicle, baseTrip) => {
      let stationTs = 0

      const station = stations.features.find(el => {
        return el.properties.CODI_PARADA === vehicle.station
      })

      let d = 200
      let min = 100
      // let i = 0
      let p = {}
      let between = 0
      let sign = 1
      baseTrip.waypoints.forEach((point, i) => {
        d = getDistance(
          point.coordinates[1],
          point.coordinates[0],
          station.geometry.coordinates[1],
          station.geometry.coordinates[0],
          'K'
        )
        if (i > 0) {
          between = getDistance(
            point.coordinates[1],
            point.coordinates[0],
            baseTrip.waypoints[i - 1].coordinates[1],
            baseTrip.waypoints[i - 1].coordinates[0],
            'K'
          )
        }
        const prev = min
        // sign = prev > between ? +1 : -1
        if (Math.abs(d) < Math.abs(min)) {
          min = d
          sign = prev > between ? +1 : -1
          stationTs = point.timestamp + (100 * (sign * d)) / baseTrip.length
          p = point
        }
      })

      console.log(
        `closest point for station ${vehicle.station} ${station.geometry.coordinates[0]},${
          station.geometry.coordinates[1]
        } on line ${baseTrip.properties.CODI_LINIA} is ${p.percentage} ${p.coordinates[0]},${
          p.coordinates[1]
        } with distance ${min * sign}`
      )

      const velocity = 11.25 // kmh
      const delta = ((vehicle['t-in-s'] / 3600) * velocity) / baseTrip.length
      const trail = (2 / baseTrip.length) * 100 // input in kms

      return {
        relative: {
          [vehicle.station]: {
            station: stationTs,
            vehicle: stationTs - delta,
            trail: stationTs - delta - trail
          }
        },
        absolute: {
          station: (stationTs * baseTrip.length) / 100,
          vehicle: ((stationTs - delta) * baseTrip.length) / 100
        },
        coordinates: {
          station: station.geometry.coordinates
        }
      }
    }

    // Fetch next vehicle for line+station
    const fetchNextVehicle = async (line, sid) => {
      console.log(`fetching vehicle for line ${line} and station ${sid}`)
      try {
        const result = await throttledAxios(
          `https://api.tmb.cat/v1/ibus/lines/${line}/stops/${sid}?app_id=${TMBAppId}&app_key=${TMBAppKey}`
        )
        if (result.data.status === 'success') {
          if (result.data.data.ibus.length > 0) {
            result.data.data.ibus.forEach(bus => {
              const vehicle = bus
              vehicle.id = `${bus.routeId}-${sid}`
              vehicle.station = sid
              // calc relative positions for this vehicle within route
              const line = parseInt(vehicle.routeId.slice(0, 3), 10)
              const direction = (parseInt(vehicle.routeId.slice(-1), 10) % 2) + 1
              const route = routes.features.find(el => {
                return el.properties.CODI_LINIA === line && el.properties.ID_SENTIT === direction
              })
              const station = stations.features.find(el => el.properties.CODI_PARADA === sid)

              const color = [Math.random() * 255, Math.random() * 255, Math.random() * 255]

              const waypoints = geometryToWaypoints(route, vehicle.id, {
                color,
                CODI_LINIA: line,
                ID_RECORREGUT: route.properties.ID_RECORREGUT
              })
              vehicle.positions = getPositionsForVehicle(vehicle, waypoints)
              vehicle.timestamp = Date.now() + vehicle['t-in-s'] * 1000
              vehicle.properties = station.properties
              setVehicles(prevVehicles => [...prevVehicles, vehicle])
              setSchedules(prevSchedules =>
                [vehicle, ...prevSchedules]
                  // prevents duplicate vehicles from the same exchange (we keep the first element on the array with a given id, which is the most recently fetched for a pair route+station)
                  .filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
                  // sort by route and vehicle relative location within full route
                  .sort((a, b) =>
                    a.routeId > b.routeId
                      ? 1
                      : a.routeId === b.routeId
                      ? a.positions.relative[Object.keys(a.positions.relative)[0]].vehicle <
                        b.positions.relative[Object.keys(b.positions.relative)[0]].vehicle
                        ? 1
                        : -1
                      : -1
                  )
              )
            })
          }

          setCounter3(prevCounter => prevCounter + 1)

          // for each vehicle:
          // a) match with its route
          // b) estimate position (in percentage) within route based on station postition and expected time
          // c) add a trip to state, clearing previous trips from the same line+station
        }
      } catch (error) {}
    }

    if (counter2 === Object.keys(lines).length && counter2 > 0) {
      console.log(`### STEP OK: routes processed for ${counter2} lines`)
      setInitProgress(prev => prev + PROGRESS_STEP)

      // fetch vehicle data for each line (excluding line X1 which doesn't work with the API)
      exchanges.features.forEach(exchange => {
        if (exchange.properties.ID_OPERADOR === 2 && exchange.properties.NOM_LINIA !== 'X1') {
          fetchNextVehicle(exchange.properties.NOM_LINIA, exchange.properties.CODI_PARADA)
        } else {
          console.log(
            `omitting line ${exchange.properties.NOM_LINIA} from ${exchange.properties.NOM_OPERADOR} at station ${exchange.properties.CODI_PARADA}`
          )
          setCounter3(prevCount => prevCount + 1)
        }
      })
    }
  }, [counter2, lines, exchanges.features, refreshCount, stations.features, routes.features])

  useEffect(() => {
    // const THRESHOLD = (2 * 3600 * 0.35) / 11.25 // 350m avg between stops at 11.25 kmh avg speed plus safety factor x3

    const mergeVehicleTrips = (keep, drop) => {
      console.log(`keeping ${keep.id} and merging ${drop.id}`)
    }

    // eslint-disable-next-line no-extend-native
    Array.prototype.averagefilter = function(fun) {
      var filtered = []
      for (let i = 0; i < this.length; i++) {
        if (fun(this[i], i, this)) filtered.push(this[i])
        else {
        }
      }
      return filtered
    }

    // eslint-disable-next-line no-extend-native
    Array.prototype.mergefilter = function(fun) {
      var filtered = []
      for (let i = 0; i < this.length; i++) {
        if (fun(this[i], i, this)) filtered.push(this[i])
        else {
          mergeVehicleTrips(this[i - 1], this[i])
          let merged = filtered.pop()
          merged.schedule = { ...merged.schedule, ...this[i].schedule }
          merged.positions.relative = {
            ...merged.positions.relative,
            ...this[i].positions.relative
          }
          merged.temp = [...this[i].waypoints, ...merged.waypoints]
          /*merged.waypoints = [...this[i].waypoints, ...merged.waypoints].filter(
            (v, i, a) =>
              a.findIndex(
                t => t.coordinates[0] === v.coordinates[0] && t.coordinates[1] === v.coordinates[1]
              ) === i
          )*/

          filtered.push(merged)
        }
      }
      return filtered
    }

    /*
    const timeDifference = (leading, following) => {
      let diff = 100

      // get last waypoint of the follower (the one closest to the leading)
      const waypointF = following.waypoints[following.waypoints.length - 1]

      // find common timestamp
      const waypointL = leading.waypoints.find(
        el =>
          el.coordinates[0] === waypointF.coordinates[0] &&
          el.coordinates[1] === waypointF.coordinates[1]
      )
      if (waypointL !== undefined) {
        diff = Math.abs(waypointL.timestamp - waypointF.timestamp)
      }

      return diff
    }
    */

    /*
    const isNotDuplicate = (suspect, i, array) => {
      console.log(`testing ${suspect.id} with index ${i} against ${array.length} elements`)
      let keep = true
      if (i !== 0) {
        const baseline = array[i - 1]
        if (suspect.properties.routeId === baseline.properties.routeId) {
          const val = timeDifference(baseline, suspect)
          keep = val > THRESHOLD
          console.log(
            `Singularity check: suspect ${suspect.id} scored ${val} against trip ${baseline.id} and results ${keep}`
          )
        }
      }
      return keep
    }
    */

    // Translate geojson geometry into trip waypoint structure
    /*
    const getTripForVehicle = (vehicle, baseTrip) => {
      let stationTs = 0

      const station = stations.features.find(el => {
        return el.properties.CODI_PARADA === vehicle.station
      })

      let d = 200
      let min = 100
      let p = {}
      let between = 0
      let sign = 1
      baseTrip.waypoints.forEach((point, i) => {
        d = getDistance(
          point.coordinates[1],
          point.coordinates[0],
          station.geometry.coordinates[1],
          station.geometry.coordinates[0],
          'K'
        )
        if (i > 0) {
          between = getDistance(
            point.coordinates[1],
            point.coordinates[0],
            baseTrip.waypoints[i - 1].coordinates[1],
            baseTrip.waypoints[i - 1].coordinates[0],
            'K'
          )
        }
        const prev = min
        // sign = prev > between ? +1 : -1
        if (Math.abs(d) < Math.abs(min)) {
          min = d
          sign = prev > between ? +1 : -1
          stationTs = point.timestamp + (100 * (sign * d)) / baseTrip.length
          p = point
        }
      })

      console.log(
        `closest point for station ${vehicle.station} ${station.geometry.coordinates[0]},${
          station.geometry.coordinates[1]
        } on line ${baseTrip.properties.CODI_LINIA} is ${p.percentage} ${p.coordinates[0]},${
          p.coordinates[1]
        } with distance ${min * sign}`
      )

      const velocity = 11.25 // kmh
      const delta = ((vehicle['t-in-s'] / 3600) * velocity) / baseTrip.length
      const trail = (2 / baseTrip.length) * 100 // input in kms

      const trip = baseTrip
      delete trip.length

      // trip.CODI_PARADA = vehicle.station
      // trip.routeId = vehicle.routeId

      // trip.station = stationTs
      // trip.vehicle = stationTs - delta
      // trip.to = trip.station
      // trip.from = trip.vehicle - trail

      trip.properties.routeId = vehicle.routeId
      trip.properties.CODI_PARADA = vehicle.station

      trip.positions = {
        relative: {
          [vehicle.station]: {
            station: stationTs,
            vehicle: stationTs - delta,
            trail: stationTs - delta - trail
          }
        }
      }

      // trip.color = hexToRgb('#e28800')

      // filter the waypoints needed
      trip.waypoints = baseTrip.waypoints.filter(
        point =>
          point.timestamp <= trip.positions.relative[vehicle.station].station &&
          point.timestamp >= trip.positions.relative[vehicle.station].trail
      )

      // find total trip distance
      let distance = 0
      trip.waypoints.forEach((point, i) => {
        // trip.waypoints[i].distance = distance
        if (i < trip.waypoints.length - 1) {
          distance += getDistance(
            point.coordinates[0],
            point.coordinates[1],
            trip.waypoints[i + 1].coordinates[0],
            trip.waypoints[i + 1].coordinates[1],
            'K'
          )
        }
      })

      // trip.length = distance

      // version C: extrapolate real timestamps based on velocity, with trip.vehicle at Date.now() + vehicle.t-in-s
      // timestamps in seconds because at trip layer we can't use unix epocs (uses 32 bit floats to manage timestamps, not enough)
      // vehicle['t-in-s'] = 10
      const now = Date.now() / 1000
      const timeref = -(TIME_ORIGIN / 1000) + now + vehicle['t-in-s']
      const rvelocity = velocity + Math.random() * 1
      console.log(`vehicle is ${vehicle['t-in-s']} s away`)
      console.log(`time reference will be ${timeref}`)
      console.log(`distance to cover: ${distance} km`)
      console.log(`time to cover distance: ${(3600 * distance) / rvelocity} s`)
      let accum = 0
      trip.waypoints.forEach((point, i) => {
        if (i > 0) {
          accum += getDistance(
            point.coordinates[0],
            point.coordinates[1],
            trip.waypoints[i - 1].coordinates[0],
            trip.waypoints[i - 1].coordinates[1],
            'K'
          )
        }
        const rempos = (distance - accum) / distance
        const remsec = (3600 * rempos * distance) / rvelocity
        // console.log(`remaining ${rempos} of distance in ${remsec} s`)

        point.timestamp = timeref - remsec
        //point.timestamp = now - TIME_ORIGIN / 1000 + 3 + i
        // console.log(`point in ${point.timestamp} s`)
      })

      //trip.expected = timeref * 1000 + TIME_ORIGIN

      trip.schedule = { [vehicle.station]: { expected: timeref * 1000 + TIME_ORIGIN } }

      console.log(vehicle)
      console.log(trip)

      return trip
    }
    */
  

    console.log(`processed vehicles for ${counter3} exchanges`)

    if (vehicles.length > 0 && counter3 === exchanges.features.length) {
      /* console.log(
        `### STEP OK: expected bus times fetched, creating trips for ${vehicles.length} vehicles`
      )
      
      console.log(vehicles)

      vehicles.forEach(vehicle => {
        console.log(
          `generating trip for vehicle with id ${vehicle.id} which should be direction ${parseInt(
            vehicle.routeId.slice(-1),
            10
          ) + 1} from route ${parseInt(vehicle.routeId.slice(0, 3), 10)}`
        )

        const line = parseInt(vehicle.routeId.slice(0, 3), 10)
        const direction = (parseInt(vehicle.routeId.slice(-1), 10) % 2) + 1
        const route = routes.features.find(el => {
          return el.properties.CODI_LINIA === line && el.properties.ID_SENTIT === direction
        })

        const color = [Math.random() * 255, Math.random() * 255, Math.random() * 255]

        const waypoints = geometryToWaypoints(route, vehicle.id, {
          CODI_LINIA: line,
          ID_RECORREGUT: route.properties.ID_RECORREGUT,
          color
        })
        const trip = getTripForVehicle(vehicle, waypoints)
        setVehicleTrips(
          prevTrips =>
            [trip, ...prevTrips]
              // prevents duplicate vehicles from the same exchange (we keep the first element on the array with a given id, which is the most recently fetched for a pair route+station)
              .filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
              // sort by route and vehicle relative location within full route
              .sort((a, b) =>
                a.properties.routeId > b.properties.routeId
                  ? 1
                  : a.properties.routeId === b.properties.routeId
                  ? a.positions.relative[Object.keys(a.positions.relative)[0]].vehicle <
                    b.positions.relative[Object.keys(b.positions.relative)[0]].vehicle
                    ? 1
                    : -1
                  : -1
              )
          // prevent duplicate vehicles from the same line (vehicles too close)
          //.mergefilter((v, i, a) => isNotDuplicate(v, i, a))
        )
      }) */
    }
  }, [counter3, vehicles, routes.features, exchanges.features, stations.features])

  // When schedules are updated, guess duplicates and calculate new trips based on arrays of stops
  // for now, only happens at init with the first loading
  useEffect(() => {
    function groupBy(arr, property) {
      return arr.reduce(function(memo, x) {
        if (!memo[x[property]]) {
          memo[x[property]] = []
        }
        memo[x[property]].push(x)
        return memo
      }, {})
    }

    const THRESHOLD = (2 * 3600 * 0.35) / 11.25 // IN SECONDS: 350m avg between stops at 11.25 kmh avg speed plus safety factor x2
    // wrong: this assumes we will be comparing stations next to one another, instead it should take into account distance between stops
    // schedule has the relative position of the vehicle and station within route, and the actual position in km

    if (schedules.length > 0 && counter3 === exchanges.features.length) {
      console.log(
        `### STEP OK: expected bus times fetched, creating scheduled trips for ${schedules.length} schedules`
      )
      setInitProgress(prev => prev + PROGRESS_STEP)

      const groupedSchedules = groupBy(schedules, 'routeId')
      console.log(groupedSchedules)

      let v = []
      Object.keys(groupedSchedules).forEach((g, index) => {
        console.log(`processing schedules for route ${g}`)
        console.log(`${groupedSchedules[g].length} schedules available`)
        const list = groupedSchedules[g]
        let vehiclestops = []
        vehiclestops.push(list[0])
        if (list.length > 1) {
          list.forEach((s, i) => {
            if (i > 0) {
              const prev = vehiclestops[vehiclestops.length - 1]
              const dif = prev['t-in-s'] - s['t-in-s'] // delta between scheduled times IN SEC
              const space = prev.positions.absolute.station - s.positions.absolute.station // delta between station positions IN KM
              const validation = (THRESHOLD * space) / 0.35
              console.log(
                `time dif is ${dif}s for ${space}km, will be compared with ${validation}s`
              )
              if (dif > validation || dif < 0) {
                v.push(vehiclestops)
                console.log('pushing multiple schedule vehicle')
                vehiclestops = []
                vehiclestops.push(s)
              } else {
                vehiclestops.push(s)
              }
            }
            if (i === list.length - 1) {
              v.push(vehiclestops)
              console.log('pushing last multiple schedule vehicle')
            }
          })
        } else {
          v.push(vehiclestops)
          console.log('pushing single schedule vehicle')
        }
      })

      console.log(v)
      setScheduledVehicles(v)
    }
  }, [schedules, counter3, exchanges.features])

  // PROCESS TRIPS FOR VEHICLES WITH SCHEDULE
  useEffect(() => {
    const getPathpointsFor = s => {
      const line = parseInt(s[0].routeId.slice(0, 3), 10)
      const direction = (parseInt(s[0].routeId.slice(-1), 10) % 2) + 1
      const trip = trips.find(
        el => el.properties.CODI_LINIA === line && el.properties.ID_SENTIT === direction
      )
      console.log(
        `using waypoints from ${trip.properties.DESC_LINIA} ${trip.properties.DESC_SENTIT}`
      )

      const pathpoints = []

      s.forEach((station, i) => {
        console.log(`checking station ${station.station}`)
        const sp = []
        // if it's the last station, get the set of waypoints between this station and a given trail length
        // if not, get waypoints between this station and the next
        if (i === s.length - 1) {
          sp.push({
            coordinates: station.positions.coordinates.station,
            type: 'Scheduled Station',
            percentage: station.positions.relative[station.station].station,
            id: station.station
          })
          trip.waypoints
            .filter(p => p.percentage < station.positions.relative[station.station].station)
            .reverse()
            .forEach(el => sp.push(el))
        } else {
          const prev = s[i + 1]
          sp.push({
            coordinates: station.positions.coordinates.station,
            type: 'Scheduled Station',
            percentage: station.positions.relative[station.station].station,
            id: station.station
          })
          trip.waypoints
            .filter(
              p =>
                p.percentage < station.positions.relative[station.station].station &&
                p.percentage > prev.positions.relative[prev.station].station
            )
            .reverse()
            .forEach(el => sp.push(el))
          sp.push({
            coordinates: prev.positions.coordinates.station,
            type: 'Station',
            percentage: prev.positions.relative[prev.station].station,
            id: prev.station
          })
        }
        pathpoints.push(...sp)
      })

      console.log(pathpoints)
      return [pathpoints, trip.length, trip.properties]
    }

    const getWaypointsFor = (schedule, pathpoints, length) => {
      const waypoints = []

      const velocity = 11.25 // kmh
      const loading = 15 // seconds

      // version D: extrapolate real timestamps based on calc velocity between scheduled stops, with trip.vehicle at Date.now() + vehicle.t-in-s
      // timestamps in seconds because at trip layer we can't use unix epocs (uses 32 bit floats to manage timestamps, not enough)
      const now = Date.now() / 1000
      const rvelocity = velocity + Math.random() * 1
      // if point is a Scheduled Station: set new time reference based on schedule['t-in-s']
      // if point is a Station or Point: calculate timestamp substracting distance * velocity to the current time reference
      let timeref = 0
      let nextref = -1
      let stepdist = 0
      let svelocity = 0
      pathpoints.forEach((point, i) => {
        if (point.type === 'Scheduled Station') {
          nextref = -1
          timeref =
            -(TIME_ORIGIN / 1000) + now + schedule.find(el => el.station === point.id)['t-in-s']
          waypoints.push({ ...point, timestamp: timeref })
          // calc next time reference
          const nextw = pathpoints.find((el, ind) => el.type === 'Station' && ind > i)
          if (nextw !== undefined) {
            console.log(
              `updating references at point ${i} ${point.percentage} with ${nextw.percentage}`
            )
            console.log(nextw)
            console.log(point)
            nextref =
              -(TIME_ORIGIN / 1000) +
              now +
              loading +
              schedule.find(el => el.station === nextw.id)['t-in-s']
            stepdist = ((point.percentage - nextw.percentage) * length) / 100
            svelocity = (3600 * stepdist) / (timeref - nextref)
          }
        } else {
          const distance = getDistance(
            point.coordinates[0],
            point.coordinates[1],
            pathpoints[i - 1].coordinates[0],
            pathpoints[i - 1].coordinates[1],
            'K'
          )
          let remsec = (3600 * distance) / rvelocity
          if (nextref > -1) {
            console.log(
              `stepdist is:${stepdist}, timeref is:${timeref}, nextref is:${nextref}, svelocity is:${svelocity}`
            )
            remsec = (3600 * distance) / svelocity
          }
          waypoints.push({ ...point, timestamp: timeref - remsec })
          timeref -= remsec
        }
      })
      return waypoints
    }

    const getTimeToLiveFor = s => {
      const arrivals = s.map(el => el.timestamp)
      return Math.max(...arrivals) + 15 * 1000
    }

    const newPaths = []

    scheduledVehicles.forEach((schedule, i) => {
      console.log(
        `generating scheduled trip for vehicle with ${
          schedule.length
        } stop(s) for direction ${parseInt(schedule[0].routeId.slice(-1), 10) +
          1} from route ${parseInt(schedule[0].routeId.slice(0, 3), 10)}`
      )
      // get path points for this set of stations: array of N segments
      const [path, length, properties] = getPathpointsFor(schedule)
      // process timestamps and set waypoints
      const waypoints = getWaypointsFor(schedule, path, length)
      // set a ttl
      const ttl = getTimeToLiveFor(schedule)
      // set displayed vehicles preventing duplicates
      newPaths.push({
        path,
        schedule,
        waypoints,
        properties: { ...properties, id: i, ttl }
      })
    })

    setPaths(prev => {
      setVariation(newPaths.length - prev.length)
      return newPaths
    })
    if (newPaths.length > 0) {
      setLastUpdate(Date.now())
      setLoadingVehicles(false)
    }
  }, [scheduledVehicles, trips])

  const refresh = 10 // in ms

  // Timer from 0 to end (trips start at 0 and end at end)
  const [looping, setLooping] = useState(0)
  useEffect(() => {
    const end = 200
    const duration = DURATION.LOOPING // in seconds
    const interval = setInterval(() => {
      const d = Date.now()
      const frame = (d % (duration * 1000)) / 1000 // goes from 0 to duration
      const percentage = (frame / duration) * end // goes from 0 to end
      setLooping(percentage)
    }, refresh)
    return () => {
      clearInterval(interval)
    }
  }, [])

  // Timer from 0 to end (trips start at 0 and end at 100)
  /*const [flashing, setFlashing] = useState(0)
  useEffect(() => {
    const end = 100
    const duration = DURATION.FLASHING // in seconds
    const interval = setInterval(() => {
      const d = Date.now()
      const frame = (d % (duration * 1000)) / 1000 // goes from 0 to duration
      const percentage = (frame / duration) * end // goes from 0 to end
      setFlashing(percentage)
    }, refresh)
    return () => {
      clearInterval(interval)
    }
  }, [])*/

  // Real time from time reference in s
  const [time, setTime] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      const d = Date.now()
      setTime((d - TIME_ORIGIN) / 1000)
    }, refresh)
    return () => {
      clearInterval(interval)
    }
  }, [])

  // Control zoom
  const [isFar, setIsFar] = useState(true)

  // Shared WebGL context between DeckGL and Mapbox. DeckGL and mapbox will both draw into this WebGL context
  const [gl, setGl] = useState()

  function onWebGLInitialized(context) {
    setGl(context)
  }

  const mapRef = useRef(null)
  const deckRef = useRef(null)

  function onMapLoad() {
    if (mapRef.current != null && deckRef.current != null) {
      const map = mapRef.current.getMap()
      const { deck } = deckRef.current

      // Add DeckGL layers between Mapbox layers
      // Above roads and below labels: road-label for Monochrome white / poi-parks-small for Decimal BW
      // Just above labels in Decimal BW: place-islets-archipelago-aboriginal
      // map.addLayer(new MapboxLayer({ id: 'bus-stops-layer', deck }), 'road-label')
      map.addLayer(
        new MapboxLayer({ id: 'vehicle-trips-layer', deck }),
        'place-islets-archipelago-aboriginal'
      )
      map.addLayer(
        new MapboxLayer({ id: 'bus-routes-layer', deck }),
        'place-islets-archipelago-aboriginal'
      )
      map.addLayer(
        new MapboxLayer({ id: 'heatmap-bikes-layer2', deck }),
        'schools' // for Monochrome White
        // "pedestrian-road" // for T-Systems white
        // 'schools' // for Decimal BW
      ) /*
    map.addLayer(
      new MapboxLayer({ id: "scatterplot-layer2", deck })
      //"road-label"
      //"national-park"
    );
*/

      console.log(map.getStyle().layers)
      setInit(true)
    }
  }

  // Control station drawer
  const [selectedId, setSelectedId] = useState(0)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  function handleClose() {
    setIsDrawerOpen(false)
    setSelectedId(0)
  }
  function handlePick(id) {
    setSelectedId(id)
  }

  // Control bus station drawer
  const [selectedBusStationId, setSelectedBusStationId] = useState({})
  const [isBusStationDrawerOpen, setIsBusStationDrawerOpen] = useState(false)
  function handleBusStationClose() {
    setIsBusStationDrawerOpen(false)
    setSelectedBusStationId({})
  }
  function handleBusStationPick(o) {
    setSelectedBusStationId(o)
  }

  // Control bus drawer
  const [selectedBusId, setSelectedBusId] = useState({})
  const [isBusDrawerOpen, setIsBusDrawerOpen] = useState(false)
  function handleBusClose() {
    setIsBusDrawerOpen(false)
    setSelectedBusId({})
  }
  function handleBusPick(o) {
    setSelectedBusId(o)
  }

  // DIALOGS
  // Control bus tracking summary
  const [busSummaryOpen, setBusSummaryOpen] = useState(false)
  const handleBusSummaryClick = () => {
    setBusSummaryOpen(true)
  }
  const handleBusSummaryClose = () => {
    setBusSummaryOpen(false)
  }
  const busSteps = getSteps({
    stations: busStations.length,
    exchanges: exchanges.features.length,
    lines: Object.keys(lines).length,
    routes: trips.length,
    schedules: schedules.length,
    vehicles: vehicles.length,
    unique: paths.length
  }).map(el => {
    switch (el.type) {
      case 'TitleIllustrationTextCard':
        return (
          <TitleIllustrationTextCard key={el.label} label={el.label} svg={el.svg} text={el.text} />
        )
      case 'IllustrationSubtitleTextCard':
        return (
          <IllustrationSubtitleTextCard
            key={el.label}
            label={el.label}
            svg={el.svg}
            text={el.text}
          />
        )
      default:
        return (
          <TitleIllustrationTextCard key={el.label} label={el.label} svg={el.svg} text={el.text} />
        )
    }
  })

  // Control bike tracking summary
  const [bikeSummaryOpen, setBikeSummaryOpen] = useState(false)
  const handleBikeSummaryClick = () => {
    setBikeSummaryOpen(true)
  }
  const handleBikeSummaryClose = () => {
    setBikeSummaryOpen(false)
  }

  // Refresh bus schedules and trigger vehicle calcs
  const handleBusRefresh = () => {
    setLoadingVehicles(true)
    setCounter3(0)
    setRefreshCount(prev => prev + 1)
  }
  useEffect(() => {
    if (counter3 === 0 && refreshCount > 0) {
      console.log('an update of bus schedules should happen')
    }
  }, [counter3, refreshCount])
  const cleanupTime = 30 * 1000
  useEffect(() => {
    const cleanupInterval = setInterval(() => {
      console.log('bus cleanup in progress')
      let v = 0
      paths.forEach(p => {
        console.log(
          `bus ${p.properties.id} has a ttl of ${(p.properties.ttl - Date.now()) / 1000}s`
        )
        if (p.properties.ttl < Date.now()) {
          console.log(`removing bus ${p.properties.id} from list`)
          v -= 1
          setPaths(prev => prev.filter(el => el.properties.id !== p.properties.id))
        }
      })
      setLastUpdate(Date.now())
      setVariation(v)
    }, cleanupTime)
    return () => {
      clearInterval(cleanupInterval)
    }
  }, [cleanupTime, paths])
  useEffect(() => {
    const refreshTime = 5 * 60 * 1000
    const refreshInterval = setInterval(() => {
      if (!loadingVehicles) {
        console.log('bus refresh in progress')
        handleBusRefresh()
      }
    }, refreshTime)
    return () => {
      clearInterval(refreshInterval)
    }
  }, [loadingVehicles])

  return (
    <div className={classes.map}>
      <Dialog
        open={bikeSummaryOpen}
        onClose={handleBikeSummaryClose}
        aria-labelledby="bike-summary-dialog-title"
        aria-describedby="bike-summary-dialog-description"
      >
        <Amplitude
          eventProperties={inheritedProps => ({
            ...inheritedProps,
            scope: [...inheritedProps.scope, 'dialog'],
            dialog: 'Bike summary'
          })}
        >
          <LogOnMount eventType="Bike summary dialog accessed" />
        </Amplitude>
        <DialogTitle id="bike-summary-dialog-title">Bikes available</DialogTitle>
        <DialogContent>
          <DialogContentText id="bike-summary-dialog-description">
            We use real-time Bicing information provided by BIMSA to track the complete set of
            stations in Barcelona.
          </DialogContentText>
          <DialogContentText>
            <strong>{bikes.update.nowAvailable}</strong> bikes are currently available to check-out
          </DialogContentText>
          <DialogContentText>
            <strong>{bikes.info.data.stations.length}</strong> <em>stations</em> tracked
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBikeSummaryClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <OnboardingDialog open={busSummaryOpen} close={handleBusSummaryClose} steps={busSteps} />

      <StationDrawer
        open={isDrawerOpen}
        id={selectedId}
        bikes={bikes.info.data.stations}
        stations={info.data.stations}
        handleClose={handleClose}
      />
      <BusStationDrawer
        open={isBusStationDrawerOpen}
        object={selectedBusStationId}
        exchanges={exchanges}
        paths={paths}
        handleClose={handleBusStationClose}
      />
      <BusDrawer
        open={isBusDrawerOpen}
        object={selectedBusId}
        paths={paths}
        stations={stations}
        handleClose={handleBusClose}
      />

      {!init && (
        <Paper elevation={0} className={classes.paperTop}>
          <ColorLinearProgress variant="determinate" value={initProgress} />
        </Paper>
      )}

      <Fade in={init} style={{ transitionDelay: `650ms` }}>
        <Paper
          elevation={0}
          className={classes.paperTopLeft}
          onClick={() => {
            handleBikeSummaryClick()
          }}
        >
          <ProgressBar ttl={bikes.info.ttl} timestamp={bikes.info.last_updated} />
          <Metric
            title="Bikes available"
            value={bikes.update.nowAvailable}
            variation={bikes.update.variation}
            icon={<DirectionsBikeIcon />}
          />
        </Paper>
      </Fade>

      <Fade in={init} style={{ transitionDelay: `800ms` }}>
        <Paper
          elevation={0}
          className={classes.paperTopRight}
          onClick={() => {
            handleBusSummaryClick()
          }}
        >
          <ProgressBar ttl={cleanupTime / 1000} timestamp={lastUpdate} right />
          <Metric
            title="Incoming vehicles"
            value={paths.length}
            variation={variation}
            icon={<DirectionsBusIcon />}
            right
          />
        </Paper>
      </Fade>

      <Fade in={init} style={{ transitionDelay: `950ms` }}>
        <Paper elevation={0} className={classes.paperBottomRight}>
          <Amplitude
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'map']
            })}
          >
            {({ logEvent }) => (
              <IconButton
                aria-label="refresh bus schedules"
                className={classes.refreshButton}
                disabled={loadingVehicles}
                onClick={() => {
                  handleBusRefresh()
                  logEvent('Requested updated bus vehicles')
                }}
              >
                <SyncIcon />
              </IconButton>
            )}
          </Amplitude>
        </Paper>
      </Fade>

      <Fade in={init} style={{ transitionDelay: `500ms` }}>
        <div className={classes.mapContainer}>
          <DeckGL
            controller
            initialViewState={initialViewState}
            ref={deckRef}
            // save a reference to the Deck instance
            onWebGLInitialized={onWebGLInitialized}
            // picking radius bigger for touch interactions
            pickingRadius={25}
            onViewStateChange={viewPort => {
              // const { viewState, interactionState, oldViewState } = viewPort
              // const { width, height, latitude, longitude, zoom } = viewState
              // call `setState` and use the state to update the map.
              const { viewState } = viewPort
              const { zoom } = viewState
              // zoom >= 14 ? setIsFar(false) : setIsFar(true)
              setIsFar(zoom < 14)
            }}
          >
            <HeatmapLayer
              id="heatmap-bikes-layer2"
              //data={bikes.info.data.stations}
              visible={isFar}
              getPosition={d =>
                coordinates[d.station_id] != null
                  ? [
                      parseFloat(coordinates[d.station_id].lon),
                      parseFloat(coordinates[d.station_id].lat)
                    ]
                  : [null, null]
              }
              getWeight={d => d.num_bikes_available}
              opacity={0.05}
              radiusPixels={200}
              threshold={0.2}
              colorRange={[
                [254, 235, 226],
                [252, 197, 192],
                [250, 159, 181],
                [247, 104, 161],
                [197, 27, 138],
                [122, 1, 119]
              ]}
            />

            <TripsLayer
              id="bus-routes-layer"
              data={trips}
              //pickable
              getPath={d => d.waypoints.map(p => p.coordinates)}
              getTimestamps={d => d.waypoints.map(p => p.timestamp)}
              getColor={d =>
                d.properties.color ? d.properties.color : hexToRgb(config.palette.accent)
              } // #ccc
              opacity={0.05}
              widthMinPixels={4}
              widthMaxPixels={60}
              rounded
              trailLength={20}
              currentTime={looping}
            />

            <TripsLayer
              id="vehicle-trips-layer"
              //data={vehicleTrips}
              data={paths}
              pickable
              autoHighlight
              highlightColor={hexToRgb('#fff')}
              getPath={d => d.waypoints.map(p => p.coordinates)}
              getTimestamps={d => d.waypoints.map(p => p.timestamp)}
              getColor={d =>
                d.properties.color ? d.properties.color : hexToRgb(config.palette.accent)
              }
              opacity={1}
              widthMinPixels={6}
              rounded
              trailLength={25}
              currentTime={time}
              onClick={({ object, x, y }) => {
                console.log({ object, x, y })

                setIsBusDrawerOpen(true)
                handleBusPick(object)
              }}
            />

            <PathLayer
              id="scheduled-segments"
              //data={paths}
              widthScale={5}
              widthMinPixels={2}
              autoHighlight
              highlightColor={hexToRgb('#fff')}
              pickable
              getPath={d => {
                console.log(`path element ${d}`)
                return d.path.map(el => el.coordinates)
              }}
              getColor={d => [Math.random() * 255, Math.random() * 255, Math.random() * 255]}
            />

            <ScatterplotLayer
              id="scatterplot-layer2"
              data={info.data.stations}
              pickable
              autoHighlight
              highlightColor={hexToRgb('#ffffff')}
              opacity={1}
              // stroked
              radiusMaxPixels={10}
              lineWidthMinPixels={1}
              getPosition={d => [parseFloat(d.lon), parseFloat(d.lat)]}
              getRadius={d => (d.capacity / 2) ** 1.2}
              getFillColor={hexToRgb(config.palette.primary)}
              getLineColor={hexToRgb(config.palette.primary)}
              onClick={el => {
                setIsDrawerOpen(true)
                handlePick(el.object.station_id)
              }}
            />

            <IconLayer
              id="t-systems-icon-layer"
              data={[
                {
                  avatar_url: '/tsi-logo-dot.png',
                  id: 't-systems-logo',
                  coordinates: [2.193287, 41.40111]
                }
              ]}
              getIcon={d => ({
                url: d.avatar_url,
                width: 256,
                height: 256,
                anchorY: 64,
                anchorX: 64
              })}
              // icon size is based on data point's contributions, between 2 - 25
              getSize={d => 150}
              sizeMaxPixels={128}
              sizeMinPixels={32}
              sizeUnits="meters"
              getPosition={d => d.coordinates}
            />

            <GeoJsonLayer
              id="bus-stops-layer"
              data={stations}
              pickable
              autoHighlight
              highlightColor={hexToRgb('#fff')}
              //stroked={false}
              //extruded
              pointRadiusMaxPixels={8}
              pointRadiusMinPixels={4}
              getRadius={25}
              getFillColor={hexToRgb('#000')}
              getLineColor={hexToRgb(config.palette.accent)}
              //getLineWidth={5}
              lineWidthMinPixels={2}
              onClick={({ object, x, y }) => {
                // console.log({ object, x, y })

                const ex = exchanges.features.filter(
                  el => el.properties.CODI_PARADA === object.properties.CODI_PARADA
                )

                const linesex = ex
                  .map(
                    xg =>
                      `${xg.properties.NOM_FAMILIA} ${xg.properties.NOM_LINIA} direcció ${xg.properties.DESTI_LINIA}`
                  )
                  .join(', ')

                setIsBusStationDrawerOpen(true)
                handleBusStationPick(object)
                console.log(object)
                console.log(
                  `Parada ${object.properties.NOM_PARADA} (${object.properties.DESC_PARADA}) ubicada a ${object.properties.ADRECA}, correspondència amb ${linesex}`
                )
              }}
            />
            {gl && (
              <StaticMap
                mapboxApiAccessToken={MapboxApikey}
                mapStyle="mapbox://styles/pbaitor/ck3h508pw02g91cqxun4yghpb"
                // T-Systems white: mapbox://styles/pbaitor/ck27vn9kv5rcd1co7k8cvkx4k
                //  ref layer for heatmap: pedestrian-road
                // Monochrome white: mapbox://styles/pbaitor/ck27wn1bt1w8n1cpcf3hvkro9
                //  ref layer for heatmap: water-shadow
                // Decimal BW: mapbox://styles/pbaitor/ck3h508pw02g91cqxun4yghpb
                //  ref layer for heatmap: schools
                ref={mapRef}
                // save a reference to the mapboxgl.Map instance
                gl={gl}
                onLoad={onMapLoad}
                visible={!busSummaryOpen}
              />
            )}
          </DeckGL>
        </div>
      </Fade>
    </div>
  )
}

export default Map
